


















































// @ is an alias to /src

import { defineComponent, PropType, watch } from '@vue/composition-api';
import { ISelect } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ISelect>,
      required: true,
    },
    value: String,
  },

  setup(props, { emit }) {
    emit('input', props.config.value);

    const updateValue = (value: string | number) => {
      emit('input', value);
      if (props.config.onChange) {
        props.config.onChange(value);
      }
    };

    watch(
      () => props.config,
      () => {
        emit('input', props.config.value);
      },
    );

    return { updateValue };
  },
});
