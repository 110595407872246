













































































// @ is an alias to /src

import Modal from '@/components/modal.vue';
import store from '@/store';
import { ISelect } from '@/components/form';
import { ISession } from '@/models/session';
import Select from '@/components/form/select.vue';
import { sessionRegistration } from '@/api/sessions';
import { inviteModal } from '../index';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
    Select,
  },
  data() {
    return {
      modal: { ...inviteModal },
      event: 0,
      exhibitor: 0,
      session: 0,
      visitor: 0,
      inviteLoading: false,
    };
  },
  computed: {
    sessionsSelect(): ISelect {
      const config: ISelect = {
        id: 'select-section',
        name: 'select_session',
        default: this.translations.exhibitors.sessions.select_session,
        label: this.translations.exhibitors.sessions.session_label,
        value: '',
        message:
          this.sessions.filter((session) => session.private).length > 0
            ? undefined
            : this.translations.exhibitors.sessions.no_private_sessions,
        options: this.sessions
          .filter((session) => session.private)
          .map((session) => ({
            label: session.name,
            value: session.id,
          })),
      };
      return config;
    },
  },
  setup() {
    const sessions = computed(() => store.getters.getSessions as ISession[]);
    const { translations } = useContext();
    return {
      sessions,
      translations,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.visitor = parseInt(this.$route.params.visitor, 10);
    this.session = parseInt(this.$route.params.session, 10);

    store.dispatch('fetchSessions', {
      event: this.event,
      exhibitor: this.exhibitor,
      type: 'exhibitor',
      page_size: 500,
    });
  },
  methods: {
    submitInvitation() {
      if (this.visitor) {
        this.inviteLoading = true;
        sessionRegistration({
          attending: this.visitor,
          session: this.session,
        })
          .then((response) => {
            console.log(response);
            this.inviteLoading = false;
            store.commit('addPopup', {
              type: 'success',
              message: this.translations.exhibitors.productInteractions.invitation_sent,
              autohide: true,
            });
          })
          .catch((err) => {
            this.inviteLoading = false;
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.nessage,
                type: 'danger',
                autohide: true,
              });
            }
          });
      }
    },
  },
});
