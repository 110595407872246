import { IModal } from '@/components';
import router from '@/router';
import store from '@/store';
import {
  ICheckbox,
  ICheckboxes,
  IDescription,
  IInput, IRadio, ISelect, ITextarea,
} from '@/components/form/index';
// import { ITicket } from '@/models/ticket';
import { ITranslations } from '@/translations/index';
import { IVisitorOnboardingInputs } from '@/views/VisitorOnboarding/index';

export const formInputs: Array<IInput | IRadio | ICheckbox | ITextarea | ISelect | IDescription> = [];

export interface INewAssignmentInputs {
  ticket_type: ISelect;
  email: IInput;
  phone_nr: IInput;
  nr_of_tickets: IInput;
  type_of_message: ICheckboxes;
}

export const preForm = {
  ticket_type: '0',
  email: '',
  name: '',
  phone_nr: 0,
  nr_of_tickets: 0,
  type_of_message: '',
};

export const basicInputs = () => {
  const translations: ITranslations = store.getters.getTranslations;
  const inputs: IVisitorOnboardingInputs = {
    name: {
      id: 'visitor-onboarding-name',
      name: 'visitor_onboarding_name',
      type: 'text',
      label: translations.common.name,
      placeholder: translations.common.name_placeholder,
      value: '',
    },
    email: {
      id: 'visitor-onboarding-email',
      name: 'visitor_onboarding_email',
      type: 'text',
      label: translations.auth.login.email,
      placeholder: translations.auth.login.email_placeholder,
      value: '',
    },
  }

  return inputs;
}

interface IFilters {
  search: IInput;
  filter: ISelect;
}

export const filters = () => {
  const translations = store.getters.getTranslations as ITranslations;
  const filters: IFilters = {
    search: {
      id: 'filter-search',
      name: 'filter_search',
      value: '',
      placeholder: translations.common.search,
      type: 'text',
    },
    filter: {
      id: 'filters-filter',
      name: 'filters_filter',
      value: 'all',

      options: [
        {
          value: 'all',
          label: translations.common.all,
        },
        {
          value: 'favorites',
          label: translations.common.favorites,
        },

      ],
    },
  };
  return filters;
};


export const inputs = () => {
  // const translations = store.getters.getTranslations as ITranslations;

  const inputs: INewAssignmentInputs = {
    ticket_type: {
      id: 'new-ticket-ticket-type',
      name: 'new_ticket_ticket_type',
      value: '',
      label: 'Bilhete',
      default: 'Selecione um tipo de bilhete',
      type: 'text',
      options: [],
    },

    email: {
      id: 'new-ticket-email',
      name: 'new_ticket_email',
      value: '',
      type: 'text',
      label: 'Visitor email',
      placeholder: 'Digite o email do visitante',
    },
    phone_nr: {
      id: 'new-ticket-phone-nr',
      name: 'new_ticket_phone_nr',
      value: '',
      type: 'number',
      maxLength: 9,
      placeholder: 'Digite o número de telemóvel do visitante',
    },
    nr_of_tickets: {
      id: 'new-ticket-nr-of-tickets',
      name: 'new_ticket_nr_of_tickets',
      value: '',
      label: 'Quantidade de bilhetes',
      type: 'number',
      placeholder: 'Digite o número de bilhetes a atribuir',
    },
    type_of_message: {
      id: 'new-ticket-nr-of-tickets',
      name: 'new_ticket_nr_of_tickets',
      value: 'email',
      label: 'Pretende enviar o convite por email ou SMS?',
      options: [{
        value: 'email',
        label: 'Email',
        checked: true,
      }, {
        value: 'phone_nr',
        label: 'Número de telemóvel',
      }],
    },
  };
  return inputs;
};

export const modal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/manage-exhibitor/${store.getters.getContextExhibitor.id}/visitors`,
    );
  },
};

export const inviteModal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: () => {
    const visitorId = parseInt(router.currentRoute.params.visitor, 10);
    router.push(
      `/event/${store.getters.getContextEvent.id}/manage-exhibitor/${store.getters.getContextExhibitor.id}/visitor/${visitorId}`,
    );
  },
};

export const statusSelect = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const statusSelect: ICheckbox = {
    id: 'ticket-status',
    name: 'ticket_status',
    label: translations.common.published,
    // message: translations.exhibitors.ticketInteractions.publish_warning,
    value: false,
  };

  return statusSelect;
};

export const ticketFilters = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const statusFilterSelect: ISelect = {
    id: 'ticket-status',
    name: 'ticket_status',
    label: translations.common.filter_by_status,
    value: 'all',
    options: [{
      label: translations.common.published,
      value: 'submitted',
    }, {
      label: translations.common.not_published,
      value: 'not-submitted',
    }, {
      label: translations.common.all,
      value: 'all',
    }],
  };

  const searchInput: IInput = {
    id: 'ticket-status',
    name: 'ticket_status',
    label: translations.common.search,
    // placeholder: translations.exhibitors.tickets.search_placeholder,
    type: 'text',
    value: '',
  };

  return { statusFilterSelect, searchInput };
};
